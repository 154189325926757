import { ChainId } from '@uniswap/sdk';
import LOCKER_ABI from './abi.json';

const LOCKER_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.KOVAN]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.CHANGI]: '0x79208eADd9FbC29116108433a38Af62D0fD83850',
  [ChainId.TESTNET]: '0xff948ef722A5541DF093bbcCC6DBa1fee12299a7',
  [ChainId.DMCMAIN]: '0x238d521207C72544A06dDdEc5bC0e1CfFA143276'
};

export { LOCKER_ABI, LOCKER_NETWORKS };
