import React, { useContext, useMemo, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import { TYPE, HideSmall } from '../../theme';
import Row, { RowBetween, RowFixed } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { LightCard } from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import CurrencyInputPanel from 'components/CurrencyInputPanel';

import { WDFI, VAN, EUR } from '../../constants';
import { ETHER, WETH } from '@uniswap/sdk';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { useLocksLength, useLocksPage, useLockTokenInformation } from 'hooks/Locker';
import dayjs from 'dayjs';
import { useTokenContract } from 'hooks/useContract';
import { tryParseAmount } from 'state/swap/hooks';
import { ethers } from 'ethers';
import { getEtherscanLink, shortenAddress } from 'utils';
import { useActiveWeb3React } from 'hooks';
import QuestionHelper from 'components/QuestionHelper';
import { useUnlockCallback } from 'hooks/useLockCallback';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
`;

const HeaderLinks = styled(Row)`
  width: auto;
  margin: 0 auto;
  padding: 0.3rem;
  justify-content: center;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  background-color: ${({ theme }) => theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: 0;
    margin-right: auto;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: fixed;
    bottom: 0;
    padding: .5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.bg3};
  `};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 0.8rem;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  :focus {
    border: 1px solid blue;
  }
`;

const NetworkCard = styled(LightCard)`
  border-radius: 0.8rem;
  padding: 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`;

const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 0.9rem;
  width: fit-content;
  padding: 0.3rem 0.6rem;
  font-weight: 500;
  transition: 0.3s;

  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 8px;
    padding: 0.3rem 7%;
    border: 1px solid ${({ theme }) => theme.bg3};

    &:not(:last-child) {
      margin-right: 2%;
    }
  `};
`;

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`;

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  price: number,
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function RowItem(props: { row: any, id: number, chainId: number }) {
  const { row, id, chainId } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const data = useLockTokenInformation(row.token);
  const { callback: unlock, error: unlockErr } = useUnlockCallback(id, data.name);

  const isUnlockable = !row.unlocked && dayjs(row.unlockTime.toNumber() * 1000).diff(dayjs(), 'second') < 0;

  const doUnlock = async () => {
    setLoading(true);
    try {
      await unlock();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const displayAmount = useMemo(() => {
    if (!data) return '0';
    const format = ethers.utils.formatUnits(row.amount, data.decimals);
    if (format.includes('.') && format.split('.')[1].length > 4) {
      return format.slice(0, format.indexOf('.') + 2);
    }
    return format; 
  }, [data, row.amount]);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {id}
        </TableCell>
        <TableCell align="center">{data.lp ? `${data.lp.tokens[0].symbol}/${data.lp.tokens[1].symbol} LP`: data.name}</TableCell>
        <TableCell align="right">{dayjs(row.unlockTime.toNumber() * 1000).format('DD/MM/YYYY HH:mm:ss')} ({row.unlocked ? 'Unlocked' : 'Locked'})</TableCell>
      </TableRow>
      {row && data && (
      <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {data.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    {data.symbol}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>Unlockable</TableCell>
                        <TableCell align="right">Recipient</TableCell>
                        <TableCell align="right">Token</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {displayAmount}
                        </TableCell>
                        <TableCell>
                          {row.unlocked ? 'claimed' : isUnlockable ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell align="right">
                          <Link href={getEtherscanLink(chainId, row.recipient, 'address')} color="inherit" target="_blank" rel="noopener">
                            {shortenAddress(row.recipient)}
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          <Link href={getEtherscanLink(chainId, row.token, 'token')} color="inherit" target="_blank" rel="noopener">
                            {shortenAddress(row.token)}
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <Typography variant="caption" gutterBottom component="div" style={{ display: 'flex', alignItems: 'center' }}>
                        Unlock Fee
                        <QuestionHelper text="On unlocking the protocol charges a fee of 1% of the amount unlocked." />
                      </Typography>
                    </div>
                    <IconButton aria-label="unlock" size="large" disabled={loading} onClick={isUnlockable ? doUnlock : undefined} color={row.unlocked ? 'info' : isUnlockable ? 'success' : 'warning'}>
                      {row.unlocked ? <LockPersonIcon /> : isUnlockable ? <LockOpenOutlinedIcon /> : <LockClockOutlinedIcon />}
                    </IconButton>
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
      </TableRow>)}
    </React.Fragment>
  );
}

export default function Locker() {
  const { account, chainId } = useActiveWeb3React();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  
  const locksLength = useLocksLength();
  const locksPage = useLocksPage(page, rowsPerPage);
  const currPage = locksPage || []

  const missingRows = rowsPerPage - currPage.length;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <AppBody maxWidth="600px">
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <HeaderLinks style={{ width: '100%', justifyContent: 'space-around' }}>
          <StyledNavLink
            id={`nav-link`}
            to={'/locker/in'}
            isActive={(match, { pathname }) =>
              false
            }
          >
            Lock
          </StyledNavLink>
          <StyledNavLink
            id={`nav-link`}
            to={'/locker/out'}
            isActive={(match, { pathname }) =>
              true
            }
          >
            Unlock
          </StyledNavLink>
        </HeaderLinks>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            {!currPage.length ? (
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom component="div">
                  No Locks
                </Typography>
              </div>
            ) : (
              <TableContainer component={Paper} style={{ borderRadius: '10px' }}>
                <Table aria-label="Locks Table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>ID</TableCell>
                      <TableCell align="center">Token</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currPage.map((row, i) => (
                      <RowItem key={`${row.token}-${i}`} row={row} id={i + page * rowsPerPage} chainId={chainId} />
                    ))}
                    {missingRows > 0 && (
                      <TableRow style={{ height: 51 * missingRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={locksLength.toNumber()}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={() => {}}
                />
              </TableContainer>
            )}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </AppBody>
  );
}
