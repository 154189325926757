import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { CurrencyAmount, JSBI, Percent, Router, SwapParameters, Trade, TradeType } from '@uniswap/sdk';
import { LOCKER_ABI, LOCKER_NETWORKS } from '../constants/locker';
import { useMemo } from 'react';
import { BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE } from '../constants';
import { useTransactionAdder } from '../state/transactions/hooks';
import { calculateGasMargin, getRouterContract, isAddress, shortenAddress } from '../utils';
import isZero from '../utils/isZero';
import { useActiveWeb3React } from './index';
import useTransactionDeadline from './useTransactionDeadline';
import useENS from './useENS';
import { useFarmContract, useLockerContract } from './useContract';
import dayjs from 'dayjs';
import { useLockFees } from './Locker';
import { useFarmPool } from './Farm';
import { formatEther } from 'ethers/lib/utils';

export function useFarmStakeCallback(
  pool: string,
  amount: CurrencyAmount | undefined,
): { callback: null | (() => Promise<string>); error: null | Error; } {
  const { account, chainId, library } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useFarmContract(pool, true);

  const methodName = 'stake';

  return useMemo(() => {
    if (!contract || !library || !chainId || !account) {
      return { callback: null, error: new Error('Missing dependencies') };
    }

    return {
      callback: async function onStake(): Promise<string> {
        const estimateCall = await contract.estimateGas[methodName](amount.raw.toString());
        const estimate = calculateGasMargin(estimateCall);

        const tx = await contract[methodName](amount.raw.toString(), {
          gasLimit: estimate,
        });

        // Staking xxx to 0x000...000
        addTransaction(tx, {
          summary: `Staking ${amount.toSignificant(4)} ${amount.currency.symbol} on Pool: ${shortenAddress(pool)}`,
        });

        return tx.hash;
      },
      error: null,
    };
  }, [contract, library, chainId, account, amount, addTransaction, pool, methodName]);
}

export function useFarmUnstakeCallback(
  pool: string,
  amount: CurrencyAmount | undefined,
): { callback: null | (() => Promise<string>); error: null | Error; } {
  const { account, chainId, library } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useFarmContract(pool, true);

  const methodName = 'withdraw';

  return useMemo(() => {
    if (!contract || !library || !chainId || !account) {
      return { callback: null, error: new Error('Missing dependencies') };
    }

    return {
      callback: async function onUnstake(): Promise<string> {
        const estimateCall = await contract.estimateGas[methodName](amount.raw.toString());
        const estimate = calculateGasMargin(estimateCall);

        const tx = await contract[methodName](amount.raw.toString(), {
          gasLimit: estimate,
        });

        // Unstaking xxx from 0x000...000
        addTransaction(tx, {
          summary: `Unstaking ${amount.toSignificant(4)} ${amount.currency.symbol} from Pool: ${shortenAddress(pool)}`,
        });

        return tx.hash;
      },
      error: null,
    };
  }, [contract, library, chainId, account, amount, addTransaction, pool, methodName]);
}

export function useFarmAddRewardCallback(
  pool: string,
  amount: CurrencyAmount | undefined,
  percentage: number,
): { callback: null | (() => Promise<string>); error: null | Error; } {
  const { account, chainId, library } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useFarmContract(pool, true);

  const methodName = 'addReward';

  return useMemo(() => {
    if (!contract || !library || !chainId || !account) {
      return { callback: null, error: new Error('Missing dependencies') };
    }

    return {
      callback: async function onAddReward(): Promise<string> {
        if (!amount) {
          return '';
        }

        const unlockablePercentage = (BigInt(amount.raw.toString()) * BigInt(percentage)) / BigInt(100);

        const estimateCall = await contract.estimateGas[methodName](amount.raw.toString(), unlockablePercentage);
        const estimate = calculateGasMargin(estimateCall);

        const tx = await contract[methodName](amount.raw.toString(), unlockablePercentage, {
          gasLimit: estimate,
        });

        addTransaction(tx, {
          summary: `Adding ${amount.toSignificant(4)} ${amount.currency.symbol} Rewards to Pool: ${shortenAddress(pool)}`,
        });

        return tx.hash;
      },
      error: null,
    };
  }, [contract, library, chainId, account, amount, addTransaction, pool, methodName]);
}
