import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import { darken } from 'polished';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import { TYPE, HideSmall } from '../../theme';
import Row, { AutoRow, RowBetween, RowFixed, RowFlat } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { LightCard } from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import { useDefaultActiveFarms, useFarmPool, useFarmPoolById } from 'hooks/Farm';
import DoubleCurrencyLogo from 'components/DoubleLogo';
import { formatEther } from 'ethers/lib/utils';
import QuestionHelper from 'components/QuestionHelper';
import Modal from 'components/Modal';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import { VAN } from 'constants/index';
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal';
import CurrencyLogo from 'components/CurrencyLogo';
import { ArrowDown, ArrowUp, ArrowLeft } from 'react-feather';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
`;

const HeaderRow = styled(RowFixed)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FarmCard = styled(LightCard)`
  position: relative;
  border-radius: 0.8rem;
  padding: 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
`;

const ProgressBar = styled.div<{ progress?: number }>`
  position: absolute;
  bottom: 4px;
  left: calc(50% - 60px);
  width: 140px;
  height: 8px;
  background: #e5e5e5;
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ progress }) => progress || 0}%;
    height: 8px;
    background: linear-gradient(90deg, #f5a623 0%, #f5a623 100%);
    border-radius: 8px;
  }
`;

const PulsingDot = styled.div<{ status?: string }>`
  display: ${({ status }) => status === 'Finished' ? 'none' : 'block'};
  position: absolute;
  right: 0px;
  width: 12px;
  height: 12px;
  background: ${({ status }) => status === 'Staking' ? '#00ff00' : '#f5a623'};
  border-radius: 50%;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
`;

const SelectedToken = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 0.8rem;
  background: ${({ theme }) => theme.bg3};
  cursor: pointer;
`;

const TextInput = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 0.8rem;
  padding: 8px 12px;
  font-size: 16px;
  outline: none;
  background: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
`;

const FarmPool = ({ address }: { address: string }) => {
  const pool = useFarmPool(address);
  const history = useHistory();

  const getCurrentTimingString = useCallback(() => {
    const now = new Date();

    if (!pool.stakingStarts || !pool.stakingEnds || !pool.withdrawStarts || !pool.withdrawEnds)
      return '';

    if (pool.stakingStarts.getTime() > now.getTime()) {
      const days = Math.floor((pool.stakingStarts.getTime() - now.getTime()) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((pool.stakingStarts.getTime() - now.getTime()) / 1000 / 60 / 60 - days * 24);
      return `Staking starts in ${days}d ${hours}h`;
    }

    if (pool.stakingEnds.getTime() > now.getTime()) {
      const days = Math.floor((pool.stakingEnds.getTime() - now.getTime()) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((pool.stakingEnds.getTime() - now.getTime()) / 1000 / 60 / 60 - days * 24);
      return `Staking ends in ${days}d ${hours}h`;
    }

    if (pool.withdrawStarts.getTime() > now.getTime()) {
      const days = Math.floor((pool.withdrawStarts.getTime() - now.getTime()) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((pool.withdrawStarts.getTime() - now.getTime()) / 1000 / 60 / 60 - days * 24);
      return `Withdraw starts in ${days}d ${hours}h`;
    }

    if (pool.withdrawEnds.getTime() > now.getTime()) {
      const days = Math.floor((pool.withdrawEnds.getTime() - now.getTime()) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((pool.withdrawEnds.getTime() - now.getTime()) / 1000 / 60 / 60 - days * 24);
      return `Pool ends in ${days}d ${hours}h`;
    }

    return 'Ended';
  }, [pool]);

  if (!pool || !pool.currency0 || !pool.currency1) {
    return null;
  }

  console.log({ pool })

  return (
    <FarmCard style={{ cursor: 'pointer' }} onClick={() => history.push(`/farm/${address}`)}>
      <PulsingDot status={pool.status} />
      <AutoColumn gap="md">
        <RowBetween>
          <HeaderRow>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <DoubleCurrencyLogo size={24} currency0={pool.currency0} currency1={pool.currency1} />
                <span style={{ fontSize: '10px', paddingLeft: '4px' }}>
                  {pool?.isLp0 ? `${pool?.pair0.token0.symbol}/${pool?.pair0.token1.symbol} LP` : pool?.currency0?.symbol}
                </span>
              </div>
              <TYPE.smallSpan style={{ marginTop: '4px' }}>
                {pool.name.replace('VSP: ', '')}
              </TYPE.smallSpan>
            </div>
          </HeaderRow>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <TYPE.small style={{ marginBottom: '2px' }}>
              {getCurrentTimingString()}
            </TYPE.small>
            {pool.stakingCap && pool.stakingCap.lte(0) && (
              <TYPE.smallSpan>
                UNCAPPED
              </TYPE.smallSpan>
            )}

            {pool.stakingCap && pool.stakingCap.gt(0) && pool.stakeState && (
              <TYPE.smallSpan>
                {formatEther(pool?.stakingCap.sub(pool?.stakeState?.stakedTotal))} {pool.currency0.symbol} left
                <ProgressBar progress={Number(pool.stakeState.stakedTotal) / Number(pool.stakingCap) * 100} />
              </TYPE.smallSpan>
            )}
          </div>
          <TYPE.small style={{ minWidth: '80px', maxWidth: '80px', textAlign: 'end', fontSize: '10px' }}>
            {formatEther(pool?.minimumMaturity.sub(pool?.minimumMaturity.mod(1e14)) || '0')} {pool?.currency1?.symbol}
          </TYPE.small>
        </RowBetween>
      </AutoColumn>
    </FarmCard>
  );
}


export default function Farm() {
  // const [loading, farms] = useDefaultActiveFarms();

  const farms = [
    "0x82460e64DC22484fB96e983a0a86943D189BF125",
    "0x945FE8c67d8Ab0B6774F4d9d2c15d42A43430Ede",
    "0x880c6BbE07ddE553ad64Ac47e050096Ce880d5A4",
    "0x787C7A37AD594Ab9906d73B9500330502aF0cadD",
    "0x2e1fb402249a35BD5954582CffBF33861AA49F78",
    "0x09678e18CD254998eA303761Acd50D93A3ED61Cb",
    "0x87AfE72A8B4a109Cccf5414924b7C989dC91f628",
    "0x73DC5c9FC16AB4538dc7B42fd32B254ec296732e"
  ]

  const [showCreateFarmModal, setShowCreateFarmModal] = useState(false);
  const [showSelectStakingTokenModal, setShowSelectStakingTokenModal] = useState(false);
  const [showSelectRewardTokenModal, setShowSelectRewardTokenModal] = useState(false);

  const [stakingToken, setStakingToken] = useState(VAN);
  const [rewardToken, setRewardToken] = useState(VAN);

  return (
    <AppBody>

      <Modal isOpen={showCreateFarmModal} onDismiss={() => setShowCreateFarmModal(true)} maxHeight={50} minHeight={50}>
        <div style={{ width: '100%', height: '100%', padding: '10px', position: 'relative' }}>
          <TYPE.largeHeader style={{ textAlign: 'center' }}>
            Create Farm Configuration
          </TYPE.largeHeader>
          <TYPE.link style={{ textAlign: 'right', fontSize: '18px', position: 'absolute', top: '15px', left: '15px', cursor: 'pointer' }} onClick={() => setShowCreateFarmModal(false)}>
            <ArrowLeft />
          </TYPE.link>
          <RowBetween style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TYPE.small style={{ marginRight: '10px', width: '75px' }}>
              Farm Name
            </TYPE.small>
            <TextInput placeholder={'VAN Giveaway pool #1'} />
          </RowBetween>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <RowBetween style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TYPE.small style={{ paddingRight: '4px' }}>
                Staking Token
              </TYPE.small>
              <SelectedToken onClick={() => setShowSelectStakingTokenModal(true)}>
                <ArrowDown />
                <CurrencyLogo currency={stakingToken} size={'24px'} />
                <span style={{ fontSize: '10px', paddingLeft: '4px' }}>
                  {stakingToken.symbol}
                </span>
              </SelectedToken>
            </RowBetween>
            <RowBetween style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TYPE.small style={{ paddingRight: '4px' }}>
                Reward Token
              </TYPE.small>
              <SelectedToken onClick={() => setShowSelectRewardTokenModal(true)}>
                <ArrowUp />
                <CurrencyLogo currency={rewardToken} size={'24px'} />
                <span style={{ fontSize: '10px', paddingLeft: '4px' }}>
                  {rewardToken.symbol}
                </span>
              </SelectedToken>
            </RowBetween>
          </div>
        </div>
        <CurrencySearchModal
          isOpen={showSelectStakingTokenModal}
          headerText={'Select Staking Token'}
          onDismiss={() => setShowSelectStakingTokenModal(false)}
          onCurrencySelect={(currency: any) => {
            setStakingToken(currency);
            setShowSelectStakingTokenModal(false);
          }}
          showCommonBases={true}
        />
        <CurrencySearchModal
          isOpen={showSelectRewardTokenModal}
          headerText={'Select Reward Token'}
          onDismiss={() => setShowSelectRewardTokenModal(false)}
          onCurrencySelect={(currency: any) => {
            setRewardToken(currency);
            setShowSelectRewardTokenModal(false);
          }}
          showCommonBases={true}
        />
      </Modal>

      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <AutoColumn gap="md" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <RowBetween>
                <TYPE.small>
                  <ButtonPrimary style={{ width: '100%', height: '30px' }} onClick={() => { }}>
                    Create Farm
                  </ButtonPrimary>
                </TYPE.small>
                <TYPE.small>
                  APY
                  <QuestionHelper text="APY may show 0.0 if the staking pool has not been started yet!" />
                </TYPE.small>
              </RowBetween>
            </AutoColumn>

            {farms.map((farm) => (
              <FarmPool key={farm} address={farm} />
            ))}

          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </AppBody>
  );
}
