import React, { useContext, useMemo, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Pair } from '@uniswap/sdk';
import { Link } from 'react-router-dom';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import FullPositionCard, { MinimalPositionCard } from '../../components/PositionCard';
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks';
import { TYPE, HideSmall } from '../../theme';
import { Text } from 'rebass';
import Card from '../../components/Card';
import { RowBetween, RowFixed } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { AutoColumn } from '../../components/Column';

import { useActiveWeb3React } from '../../hooks';
import { usePairs } from '../../data/Reserves';
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks';
import { Dots } from '../../components/swap/styleds';
import { WDFI } from '../../constants';
import { BigNumber } from 'ethers';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
`;

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Pool() {
  const theme = useContext(ThemeContext);
  const { account } = useActiveWeb3React();
  const [externalTokens, setExternalTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs(externalTokens);
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        1
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);
  let allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));
  allV2PairsWithLiquidity = allV2PairsWithLiquidity.filter((pair) => pair.involvesToken(WDFI) && pair.reserveOf(WDFI).greaterThan(BigInt(0)));

  // sort by highest to lowest liquidity
  allV2PairsWithLiquidity = allV2PairsWithLiquidity.sort((a, b) => {
    const aLiquidity = a.reserveOf(WDFI);
    const bLiquidity = b.reserveOf(WDFI);

    if (aLiquidity.equalTo(bLiquidity)) return 0;

    return aLiquidity.greaterThan(bLiquidity) ? -1 : 1;
  });

  // only get top 10
  allV2PairsWithLiquidity = allV2PairsWithLiquidity.slice(0, 10);

  useEffect(() => {
    fetch('https://mainnet-dmc.mydefichain.com:8441/tokens?type=JSON')
      .then((response) => response.json())
      .then((data) => {
        // top 10 tokens
        // example string
        // "<tr data-identifier-hash=\"0x0074b7eb472a1fb2c60915ddaf9b122ec5892e32\">\n  <td class=\"stakes-td\">\n      <!-- incremented number by order in the list -->\n      <span class=\"color-lighten\">\n1\n      </span>\n  </td>\n  <td class=\"token-icon\">\n\n  </td>\n  <td class=\"stakes-td\">\n\n<a class=\"text-truncate\" data-test=\"token_link\" href=\"/token/0x0074b7eb472a1fb2c60915ddaf9b122ec5892e32\">VanillaSwap Token (VAN)</a>\n  </td>\n  <td class=\"stakes-td\">\n\n\n<a data-test=\"address_hash_link\" href=\"/address/0x0074b7eb472A1FB2c60915ddAf9B122ec5892e32\">\n<span class=\"contract-address\" data-address-hash=\"0x0074b7eb472A1FB2c60915ddAf9B122ec5892e32\">\n\n\n\n        <span data-toggle=\"tooltip\" data-placement=\"top\" title=\"0x0074b7eb472a1fb2c60915ddaf9b122ec5892e32\" data-custom-class=\"\">\n          <span class=\"d-none d-md-none d-lg-inline d-xl-inline\">VanillaSwap Token</span>\n          <span class=\"d-inline d-md-inline d-lg-none d-xl-none\">VanillaS..en</span>\n          <span> (0x0074b7–892e32)</span>\n        </span>\n\n\n\n</span>\n\n</a>\n\n\n  </td>\n  <td class=\"stakes-td\">\n\n      <span data-selector=\"circulating-market-cap-usd\">N/A</span>\n\n  </td>\n  <td class=\"stakes-td\">\n\n      <span data-test=\"token_supply\">100,000,000</span>\n VAN\n  </td>\n  <td class=\"stakes-td\">\n    <span class=\"mr-4\">\n      <span data-test=\"transaction_count\">\n2849\n    </span>\n  </td>\n</tr>\n"
        let items = data.items.filter((item: string) => item.includes('VAN-LP'));
        items = items.slice(0, 10);
        items = items.map((item: string) => {
          const address = item.match(/(?<=href=\"\/address\/)[^\"]*/g);
          return address;
        });
        setExternalTokens(items);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <AppBody>
      <PageWrapper>
        <SwapPoolTabs active={'analytics'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
              <HideSmall>
                <TYPE.mediumHeader style={{ justifySelf: 'flex-start' }}>
                  Top ~10 pools (Minimal by liquidity of DFI/WDFI)
                </TYPE.mediumHeader>
              </HideSmall>
            </TitleRow>

            {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </Card>
            ) : v2IsLoading || loading ? (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </EmptyProposals>
            ) : allV2PairsWithLiquidity?.length > 0 ? (
              <>
                {allV2PairsWithLiquidity.map((v2Pair) => (
                  <MinimalPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} showcase={true} />
                ))}
              </>
            ) : (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  No Liquidity pools found
                </TYPE.body>
              </EmptyProposals>
            )}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </AppBody>
  );
}
