import { ethers } from 'ethers';
import { useActiveWeb3React } from 'hooks';
import { useSingleCallResult } from '../state/multicall/hooks';
import { useFarmContract, useFarmFactoryContract, useLockerContract, usePairContract, useTokenContract } from './useContract';
import { useEffect, useMemo, useState } from 'react';
import { useCurrency } from './Tokens';
import { useCurrencyBalances } from 'state/wallet/hooks';
import { getContract } from 'utils';
import { ERC20_ABI } from 'constants/abis/erc20';
import { DEFAULT_ACTIVE_FARM_URLS } from 'constants/lists';

export function useFarmPool(address: string): any | null {
  if (!address || address === '' || !ethers.utils.isAddress(address)) {
    return {};
  }

  const { account, library } = useActiveWeb3React();
  const pool = useFarmContract(address, true);
  const [isToken0Lp0, setIsToken0Lp0] = useState({symbol: null, name: null, address: null});
  const [isToken0Lp1, setIsToken0Lp1] = useState({symbol: null, name: null, address: null});
  const [isToken1Lp0, setIsToken1Lp0] = useState({symbol: null, name: null, address: null});
  const [isToken1Lp1, setIsToken1Lp1] = useState({symbol: null, name: null, address: null});


  const name = useSingleCallResult(pool, 'name')?.result?.[0] ?? null;
  
  const token0 = useSingleCallResult(pool, 'tokenAddress')?.result?.[0] ?? null;
  const token1 = useSingleCallResult(pool, 'rewardTokenAddress')?.result?.[0] ?? null;

  const currency0 = useCurrency(token0);
  const currency1 = useCurrency(token1);

  const [isLp0, setIsLp0] = useState(false); // [token0, token1]
  const [isLp1, setIsLp1] = useState(false); // [token1, token0]

  const pair0 = usePairContract(token0);
  const pair1 = usePairContract(token1);

  if (currency0?.symbol === 'VAN-LP' && currency0?.name === 'VanillaSwap LP' && !isLp0) {
    setIsLp0(true);
    if (!isToken0Lp0.symbol && !isToken0Lp0.name) {
      pair0?.token0()?.then((t0: any) => {
        pair0?.token1()?.then((t1: any) => {
          const t0c = getContract(t0, ERC20_ABI, library, account);
          const t1c = getContract(t1, ERC20_ABI, library, account);
          t0c.symbol().then((s: any) => {
            t0c.name().then((n: any) => {
              setIsToken0Lp0({ symbol: s, name: n, address: t0 });
            });
          });

          t1c.symbol().then((s: any) => {
            t1c.name().then((n: any) => {
              setIsToken0Lp1({ symbol: s, name: n, address: t1 });
            });
          });
        });
      });
    }
  }

  if (currency1?.symbol === 'VAN-LP' && currency1?.name === 'VanillaSwap LP' && !isLp1) {
    setIsLp1(true);
    if (!isToken1Lp0.symbol && !isToken1Lp0.name) {
      pair1?.token0()?.then((t0: any) => {
        pair1?.token1()?.then((t1: any) => {
          const t0c = getContract(t0, ERC20_ABI, library, account);
          const t1c = getContract(t1, ERC20_ABI, library, account);
          t0c.symbol().then((s: any) => {
            t0c.name().then((n: any) => {
              setIsToken1Lp0({ symbol: s, name: n, address: t0 });
            });
          });

          t1c.symbol().then((s: any) => {
            t1c.name().then((n: any) => {
              setIsToken1Lp1({ symbol: s, name: n, address: t1 });
            });
          });
        });
      });
    }
  }
  
  const currencyBalances = useCurrencyBalances(account ?? undefined, [currency0, currency1]);

  const reward = useSingleCallResult(pool, 'rewardOf', [account])?.result?.[0] ?? null;
  const stakingCap = useSingleCallResult(pool, 'stakingCap')?.result?.[0] ?? null;
  const stakeState = useSingleCallResult(pool, 'stakeState')?.result ?? null;
  const rewardState = useSingleCallResult(pool, 'rewardState')?.result ?? null;

  const userStaked = useSingleCallResult(pool, 'stakeOf', [account])?.result?.[0] ?? null;

  const minimumMaturity = useSingleCallResult(pool, 'minimumRewardMaturity')?.result?.[0] ?? null;

  let stakingStarts = useSingleCallResult(pool, 'stakingStarts')?.result?.[0] ?? null;
  let stakingEnds = useSingleCallResult(pool, 'stakingEnds')?.result?.[0] ?? null;
  let withdrawStarts = useSingleCallResult(pool, 'withdrawStarts')?.result?.[0] ?? null;
  let withdrawEnds = useSingleCallResult(pool, 'withdrawEnds')?.result?.[0] ?? null;

  stakingStarts = new Date(stakingStarts ? stakingStarts.toNumber() * 1000 : 0);
  stakingEnds = new Date(stakingEnds ? stakingEnds.toNumber() * 1000 : 0);
  withdrawStarts = new Date(withdrawStarts ? withdrawStarts.toNumber() * 1000 : 0);
  withdrawEnds = new Date(withdrawEnds ? withdrawEnds.toNumber() * 1000 : 0);

  const status = useMemo(() => {
    if (Date.now() < stakingStarts.getTime()) {
      return 'Pending';
    } else if (Date.now() < stakingEnds.getTime()) {
      return 'Staking';
    } else if (Date.now() < withdrawStarts.getTime()) {
      return 'Yielding';
    } else if (Date.now() < withdrawEnds.getTime()) {
      return 'Maturity';
    } else {
      return 'Finished';
    }
  }, [stakingStarts, stakingEnds, withdrawStarts, withdrawEnds]);

  return {
    name,
    address,
    status,
    userStaked,
    reward,
    currency0,
    currency1,
    pair0: {
      token0: isToken0Lp0,
      token1: isToken0Lp1,
    },
    pair1: {
      token0: isToken1Lp0,
      token1: isToken1Lp1,
    },
    isLp0,
    isLp1,
    balances: currencyBalances,
    stakeState,
    rewardState,
    stakingCap,
    stakingStarts,
    stakingEnds,
    withdrawStarts,
    withdrawEnds,
    minimumMaturity,
    contract: pool,
  };
}

export function useFarmPoolById(id: number): any | null {
  const farmFactory = useFarmFactoryContract();

  const poolAddress = useSingleCallResult(farmFactory, 'allPools', [id])?.result?.[0] ?? null;
  const pool = useFarmPool(poolAddress);

  return { 
    ...pool,
    address: poolAddress,
  };
}

export function useDefaultActiveFarms(): [loading: boolean, farms: any[]] {
  const list = DEFAULT_ACTIVE_FARM_URLS;
  const [loading, setLoading] = useState(true);
  const [farms, setFarms] = useState<any[]>([]);

  useEffect(() => {
    let farms: any[] = [];
    list.forEach(async (url, index) => {
      try {
        const linkRes = await fetch(url);
        const linkJson = await linkRes.json();

        farms = farms.concat(linkJson.farms);

        if (index === list.length - 1) {
          setFarms(farms);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }, []);
  return [loading, farms];
}
