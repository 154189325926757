import { ChainId } from '@uniswap/sdk';
import FARMFACTORY_ABI from './factoryAbi.json';
import POOL_ABI from './abi.json';

const FARMFACTORY_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.KOVAN]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.CHANGI]: '',
  [ChainId.TESTNET]: '0xbB72DDebe8C50607C073D3Fed6fBE0677b57Cfe6',
  [ChainId.DMCMAIN]: '0xbe1cDA7f607a2671c12a709a7e720EEaE497a208'
};

export { FARMFACTORY_NETWORKS, FARMFACTORY_ABI, POOL_ABI };

