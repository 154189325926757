import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { CurrencyAmount, JSBI, Percent, Router, SwapParameters, Trade, TradeType } from '@uniswap/sdk';
import { LOCKER_ABI, LOCKER_NETWORKS } from '../constants/locker';
import { useMemo } from 'react';
import { BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE } from '../constants';
import { useTransactionAdder } from '../state/transactions/hooks';
import { calculateGasMargin, getRouterContract, isAddress, shortenAddress } from '../utils';
import isZero from '../utils/isZero';
import { useActiveWeb3React } from './index';
import useTransactionDeadline from './useTransactionDeadline';
import useENS from './useENS';
import { useLockerContract } from './useContract';
import dayjs from 'dayjs';
import { useLockFees } from './Locker';

  // function lockWithVan(address token, address recipient, uint amount, uint unlockTime) public lock {
  // function lockNative(address token, address recipient, uint amount, uint unlockTime) public lock {

export function useUnlockCallback(
  id: number,
  tokenName: string,
): { callback: null | (() => Promise<string>); error: null | Error; } {
  const { account, chainId, library } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useLockerContract(true);

  const methodName = 'unlock';

  return useMemo(() => {
    if (!contract || !library || !chainId || !account) {
      return { callback: null, error: new Error('Missing dependencies') };
    }

    return {
      callback: async function onUnlock(): Promise<string> {
        const estimateCall = await contract.estimateGas[methodName](id);
        const estimate = calculateGasMargin(estimateCall);

        const tx = await contract[methodName](id, {
          gasLimit: estimate,
        });

        addTransaction(tx, {
          summary: `Unlocking safe ID: ${id} ${tokenName}`,
        });

        return tx.hash;
      },
      error: null,
    };
  }, [id, tokenName, contract, library, chainId, account, methodName, addTransaction]);
}

export function useLockerCallback(
  amount: CurrencyAmount | undefined,
  recipientAddressOrName: string | undefined,
  date: dayjs.Dayjs,
  withVanilla: boolean = false
): { callback: null | (() => Promise<string>); error: null | Error; } {
  const { account, chainId, library } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useLockerContract(true);

  const fees = useLockFees();

  const { address: recipientAddress } = useENS(recipientAddressOrName);
  const recipient = recipientAddressOrName === null ? account : recipientAddress;

  const methodName = withVanilla ? 'lockWithVan' : 'lockNative';

  return useMemo(() => {
    if (!amount || !contract || !library || !chainId || !account || !amount.currency) {
      return { callback: null, error: new Error('Missing dependencies') };
    }

    if (!recipient) {
      return { callback: null, error: new Error('Missing recipient') };
    }

    return {
      callback: async function onLock(): Promise<string> {
        const estimateCall = await contract.estimateGas[methodName]((amount.currency as any).address, recipient, amount.raw.toString(), date.unix(), {
          value: withVanilla ? undefined : fees.nativeFee,
        });
        const estimate = calculateGasMargin(estimateCall);

        const tx = await contract[methodName]((amount.currency as any).address, recipient, amount.raw.toString(), date.unix(), {
          value: withVanilla ? undefined : fees.nativeFee,
          gasLimit: estimate,
        });

        addTransaction(tx, {
          summary: `Lock ${amount.toSignificant(4)} ${amount.currency.symbol} until ${date.format('YYYY-MM-DD HH:mm:ss')} to ${
            recipientAddressOrName && isAddress(recipientAddressOrName) ? shortenAddress(recipientAddressOrName) : recipientAddressOrName
          }`,
        });

        return tx.hash;
      },
      error: null,
    };
  }, [amount, contract, library, chainId, account, recipient, methodName, addTransaction, recipientAddressOrName, withVanilla]);
}
