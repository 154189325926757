const VANILLA_TESTNET = 'https://raw.githubusercontent.com/vanilla-labs-org/token-list/main/testnet.json';
const VANILLA_MAINNET = 'https://raw.githubusercontent.com/vanilla-labs-org/token-list/main/mainnet.json';

const VANILLA_FARM_MAINNET = 'https://raw.githubusercontent.com/vanilla-labs-org/token-list/main/farm/mainnet.json';

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [VANILLA_TESTNET, VANILLA_MAINNET];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [VANILLA_TESTNET, VANILLA_MAINNET];

// default list of active farms
export const DEFAULT_ACTIVE_FARM_URLS: string[] = [VANILLA_FARM_MAINNET];
