import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import { TYPE, HideSmall } from '../../theme';
import Row, { RowBetween, RowFixed } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { LightCard } from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { RouteComponentProps } from 'react-router-dom';
import { useActiveWeb3React } from '../../hooks';
import dayjs from 'dayjs';

import { WDFI, VAN, EUR } from '../../constants';
import { ETHER, TokenAmount, WETH } from '@uniswap/sdk';
import { useCurrencyBalance } from 'state/wallet/hooks';
import { Fraction } from '@uniswap/sdk';
import { tryParseAmount } from 'state/swap/hooks';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import QuestionHelper from 'components/QuestionHelper';
import { useLockerCallback } from 'hooks/useLockCallback';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { LOCKER_NETWORKS } from 'constants/locker';
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks';
import { useCurrency } from 'hooks/Tokens';
import { usePair } from 'data/Reserves';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
`;


const HeaderLinks = styled(Row)`
  width: auto;
  margin: 0 auto;
  padding: 0.3rem;
  justify-content: center;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  background-color: ${({ theme }) => theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: 0;
    margin-right: auto;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: fixed;
    bottom: 0;
    padding: .5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.bg3};
  `};
`;

const WarningCard = styled(LightCard)`
  position: absolute;
  z-index: 2;
  top: -35px;
  left: 0px;
  background-color: ${({ theme }) => theme.yellow2};
  border-radius: 12px;
  /* padding: 0.5rem 1rem; */
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -10px;
  `};
`;


const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 0.9rem;
  width: fit-content;
  padding: 0.3rem 0.6rem;
  font-weight: 500;
  transition: 0.3s;

  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 8px;
    padding: 0.3rem 7%;
    border: 1px solid ${({ theme }) => theme.bg3};

    &:not(:last-child) {
      margin-right: 2%;
    }
  `};
`;

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`;

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

export default function Locker({
  match: {
    params: { currencyId },
  },
  history,
}: RouteComponentProps<{ currencyId?: string }>) {
  const WARNING_KEY = 'WARNING::LOCKER::OUT';
  let warning = localStorage.getItem(WARNING_KEY);
  if (!warning) {
    localStorage.setItem(WARNING_KEY, '0');
    warning = '0';
  }

  const [hiddenWarning, setHiddenWarning] = useState(warning === '1');
  const [lockAmount, setLockAmount] = useState('');
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [currency, setCurrency] = useState(VAN);
  const [pair, setPair] = useState<any>();
  const [useVan, setUseVan] = useState(false);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);

  const { account, chainId, library } = useActiveWeb3React();

  const balance = useCurrencyBalance(account, currency);

  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  );

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );

  const [approvalLock, approveLockCallback] = useApproveCallback(tryParseAmount(lockAmount, currency), LOCKER_NETWORKS[chainId]);
  const [approvalLockFee, approveLockFeeCallback] = useApproveCallback(tryParseAmount('100', VAN), LOCKER_NETWORKS[chainId]);

  const { callback: lock, error: lockErr } = useLockerCallback(
    tryParseAmount(lockAmount, currency), account, date, useVan
  );

  const setLockAmountCallback = useCallback(
    (value: string) => {
      setLockAmount(value);
    },
    [setLockAmount]
  );

  const setMaxCallback = useCallback(() => {
    setLockAmount(balance?.toExact() ?? '');
  }, [balance, setLockAmount]);

  const doLock = useCallback(async () => {
    if (!library || !account) return;
    setLoading(true);
    try {
      await lock();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [library, account, lock]);

  useEffect(() => {
    if (currencyId) {
      const currCurrency = tokenPairsWithLiquidityTokens.find((tpwlt) => tpwlt.liquidityToken.address === currencyId);
      if (currCurrency && currency.address !== currCurrency.liquidityToken.address) {
        (currCurrency.liquidityToken as any).symbol = currCurrency.tokens[0].symbol.replace('WETH', 'DFI') + ':' + currCurrency.tokens[1].symbol.replace('WETH', 'DFI') + ' LP';
        setCurrency(currCurrency.liquidityToken);
      }
    }
  }, [currencyId, liquidityTokens, currency, setCurrency]);

  useEffect(() => {
    if (currency.symbol === 'ETH' || currency.symbol === 'WETH') {
      setErr('Cannot lock DFI');
      return;
    }

    const parsedAmount = tryParseAmount(lockAmount, currency);

    // check if the input is empty
    if (!lockAmount || lockAmount === '0' || !parsedAmount || parsedAmount.equalTo('0')) {
      setErr('Enter an amount');
      return;
    }

    // check if the user has a balance for the selected currency
    if (balance && balance.equalTo('0')) {
      setErr('Insufficient balance');
      return;
    }

    // check if the input amount is greater than the balance
    if (parsedAmount.greaterThan(balance)) {
      setErr('Insufficient balance');
      return;
    }

    if (!date) {
      setErr('Select a date');
      return;
    }

    // check if the date is in the past
    if (date.isBefore(dayjs())) {
      setErr('Date must be in the future');
      return;
    }

    setErr('');
  }, [balance, lockAmount, currency, date]);

  const getLockTimeAhead = useCallback(() => {
    if (!date) return '';
    const diffMinutes = date.diff(dayjs(), 'minutes');
    const diffHours = date.diff(dayjs(), 'hours');
    const diffDays = date.diff(dayjs(), 'days');
    const diffMonths = date.diff(dayjs(), 'months');

    if (diffMinutes < 60) {
      return `${diffMinutes} minutes`;
    } else if (diffHours < 24) {
      return `${diffHours} hours`;
    } else if (diffDays < 30) {
      return `${diffDays} days`;
    } else {
      return `${diffMonths} months`;
    }
  }, [date]);

  return (
    <AppBody>
      {!hiddenWarning && (
        <WarningCard>
          <TYPE.mediumHeader style={{ fontSize: '1rem' }}>Warning</TYPE.mediumHeader>
          <TYPE.body style={{ fontSize: '0.8rem' }}>
            Locking your tokens has no benefits. You will not earn any rewards for locking your tokens.
          </TYPE.body>
          <TYPE.body style={{ fontSize: '0.8rem' }}>
            This Locker is intended for project owners and advanced users, do not use if you are not sure what you are doing.
          </TYPE.body>
          <ButtonPrimary
            onClick={() => {
              localStorage.setItem(WARNING_KEY, '1');
              setHiddenWarning(true);
            }}
          >
            I understand
          </ButtonPrimary>
        </WarningCard>
      )}
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <HeaderLinks style={{ width: '100%', justifyContent: 'space-around' }}>
          <StyledNavLink
            id={`nav-link`}
            to={'/locker/in'}
            isActive={(match, { pathname }) =>
              true
            }
          >
            Lock
          </StyledNavLink>
          <StyledNavLink
            id={`nav-link`}
            to={'/locker/out'}
            isActive={(match, { pathname }) =>
              false
            }
          >
            Unlock
          </StyledNavLink>
        </HeaderLinks>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%', gridRowGap: '4px' }}>
            <div style={{ marginTop: '10px' }}>
              <CurrencyInputPanel
                label={'Lock Amount'}
                value={lockAmount}
                hideBalance={false}
                onUserInput={setLockAmountCallback}
                onMax={setMaxCallback}
                onCurrencySelect={setCurrency as any}
                disableCurrencySelect={false}
                showMaxButton={true}
                currency={currency}
                id="lockin-currency-input"
              />
            </div>
            <div style={{ width: '100%', display: 'flex', height: 'fit-content', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>Use VAN as fee?</p>
                <QuestionHelper text="If you have VAN in your wallet, you can use it to pay the fee for locking your tokens. If you don't have VAN, you will need to pay the fee in DFI." />
              </div>
              <Toggle isActive={useVan} toggle={() => setUseVan((o) => !o)} />
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>Fee: {useVan ? 'VAN' : 'DFI'}</p>
                <QuestionHelper text="On unlocking the tokens the protocol will take a 1% fee from the amount of tokens you are unlocking." />
              </div>
              <p>Fee amount: {useVan ? '100' : '0.1'}</p>
            </div>
            <StaticDateTimePicker
              minDate={dayjs()}
              slots={{
                actionBar: () => <div />,
              }}
              onChange={(date) => setDate(date)}
            />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              {useVan && (approvalLockFee === ApprovalState.NOT_APPROVED || approvalLockFee === ApprovalState.PENDING) ? (
                <ResponsiveButtonPrimary
                  onClick={approveLockFeeCallback}
                  disabled={approvalLockFee === ApprovalState.PENDING}
                >
                  {approvalLockFee === ApprovalState.PENDING ? 'Approving..' : 'Approve VAN Fee'}
                </ResponsiveButtonPrimary>
              ) : approvalLock === ApprovalState.NOT_APPROVED || approvalLock === ApprovalState.PENDING ? (
                <ResponsiveButtonPrimary
                  onClick={approveLockCallback}
                  disabled={approvalLock === ApprovalState.PENDING}
                >
                  {approvalLock === ApprovalState.PENDING ? 'Approving..' : 'Approve Lock'}
                </ResponsiveButtonPrimary>
              ) : (
                <ResponsiveButtonPrimary
                  onClick={doLock}
                  disabled={err !== '' || loading}
                >
                  {loading ? 'Locking..' : err ? err : `Lock ${currency.symbol} for ${getLockTimeAhead()}`}
                </ResponsiveButtonPrimary>
              )}
            </div>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </AppBody>
  );
}
