import { BigNumber, ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useActiveWeb3React } from 'hooks';
import { useSingleCallResult } from '../state/multicall/hooks';
import { useLockerContract, usePairContract, useTokenContract } from './useContract';
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks';
import { useMemo } from 'react';

export function useLockFees(): {
  vanFee: BigNumber | null,
  nativeFee: BigNumber | null,
  unlockPercentFee: BigNumber | null
} | undefined {
  const locker = useLockerContract();
  const vanFee = useSingleCallResult(locker, 'vanFee')?.result?.[0] ?? null;
  const nativeFee = useSingleCallResult(locker, 'nativeFee')?.result?.[0] ?? null;
  const unlockPercentFee = useSingleCallResult(locker, 'unlockPercentFee')?.result?.[0] ?? null;

  return { vanFee, nativeFee, unlockPercentFee };
}

export function useUnlockable(id: number): boolean | undefined {
  const locker = useLockerContract();
  return useSingleCallResult(locker, 'unlockable', [id])?.result?.[0] ?? null;
}

export function useLocksPage(page: number, pageSize: number): any[] | null {
  const { account } = useActiveWeb3React();
  const locker = useLockerContract();
  return useSingleCallResult(locker, 'getLocks', [account, page, pageSize])?.result?.[0] ?? null;
}

export function useLocksLength(): BigNumber | null {
  const { account } = useActiveWeb3React();
  const locker = useLockerContract();
  return useSingleCallResult(locker, 'getLocksLength', [account])?.result?.[0] ?? null;
}

export function useGlobalTokensLocked(token: string): string | null {
  const tokenContract = useTokenContract(token);
  const locker = useLockerContract();

  const balance = useSingleCallResult(tokenContract, 'balanceOf', [locker.address])?.result?.[0] ?? null;
  const decimals = useSingleCallResult(tokenContract, 'decimals')?.result?.[0] ?? null;

  const format = ethers.utils.formatUnits(balance ?? '0', decimals ?? '0');

  if (format.includes('.') && format.split('.')[1].length > 4) {
    return format.slice(0, format.indexOf('.') + 2);
  }

  return format
}

export function useLockTokenInformation(address: string): any | null {
  const token = usePairContract(address, true);
  const symbol = useSingleCallResult(token, 'symbol')?.result?.[0] ?? null;
  const name = useSingleCallResult(token, 'name')?.result?.[0] ?? null;
  const decimals = useSingleCallResult(token, 'decimals')?.result?.[0] ?? null;

  let lp = null;

  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  );

  tokenPairsWithLiquidityTokens.forEach((pair) => {
    if (pair.tokens[0].symbol === 'WETH') {
      (pair.tokens[0] as any).symbol = 'WDFI';
    }
    if (pair.tokens[1].symbol === 'WETH') {
      (pair.tokens[1] as any).symbol = 'WDFI';
    }
  });

  const currentLp = tokenPairsWithLiquidityTokens.find((pair) => pair.liquidityToken.address === address);

  if (currentLp) {
    lp = currentLp;
  }

  return { lp, name, symbol, decimals };
}
