import styled, { ThemeContext } from 'styled-components';
import { NavLink, RouteComponentProps, useHistory } from 'react-router-dom';
import AppBody from '../AppBody';
import { TYPE, HideSmall } from '../../theme';
import Row, { RowBetween, RowFixed } from '../../components/Row';
import { ButtonEmpty, ButtonError, ButtonPrimary, ButtonSecondary } from '../../components/Button';
import { GreyCard, LightCard } from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import Slider from '../../components/Slider';

import { useFarmPool } from 'hooks/Farm';
import { ethers } from 'ethers';
import DoubleCurrencyLogo from 'components/DoubleLogo';
import { useCallback, useState } from 'react';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Plus } from 'react-feather';
import CurrencyLogo from 'components/CurrencyLogo';
import { formatEther, parseEther } from 'ethers/lib/utils';
import QuestionHelper from 'components/QuestionHelper';
import { useActiveWeb3React } from 'hooks';
import { useFarmAddRewardCallback, useFarmStakeCallback, useFarmUnstakeCallback } from 'hooks/useFarmCallback';
import { tryParseAmount } from 'state/swap/hooks';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { getEtherscanLink } from 'utils';
import Modal from 'components/Modal';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
`;

const ProgressBar = styled.div<{ progress?: number }>`
  position: absolute;
  bottom: 4px;
  left: calc(50% - 70px); // width / 2
  width: 140px;
  height: 8px;
  background: #e5e5e5;
  border-radius: 8px 8px 8px 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ progress }) => progress || 0}%;
    height: 8px;
    background: linear-gradient(90deg, #f5a623 0%, #f5a623 100%);
    border-radius: 8px;
  }
`;

const PulsingDot = styled.div<{ status?: string }>`
  display: ${({ status }) => status === 'Finished' ? 'none' : 'block'};
  top: 0px;
  right: 2px;
  position: absolute;
  width: 12px;
  height: 12px;
  background: ${({ status }) => status === 'Staking' ? '#00ff00' : '#f5a623'};
  border-radius: 50%;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
`;

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // flex-wrap: wrap;
    // gap: 12px;
    // width: 100%;
    // flex-direction: column;
  `};
`;

const DetailsRow = styled(RowBetween)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  padding-bottom: 8px;
`;

const DetailsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const IconButton = styled(ButtonEmpty)`
  padding: 0;
  margin-left: 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.green1};
  background-color: ${({ theme }) => theme.bg3};

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`;

export default function FarmPool({
  match: {
    params,
  }
}: RouteComponentProps<{ pool }>) {
  const pool = useFarmPool(params.pool);
  console.log(pool);
  const { account, chainId } = useActiveWeb3React();
  const hisotry = useHistory();

  const [amount, setAmount] = useState('');
  const [reward, setReward] = useState('');
  const [earlyReward, setEarlyReward] = useState(100);
  const [loading, setLoading] = useState(false);
  const [exitPoolOpen, setExitPoolOpen] = useState(false);
  const [addRewardOpen, setAddRewardOpen] = useState(false);

  const stake = useFarmStakeCallback(params.pool, tryParseAmount(amount, pool?.currency0));
  const unstake = useFarmUnstakeCallback(params.pool, tryParseAmount(amount, pool?.currency0));
  const addReward = useFarmAddRewardCallback(params.pool, tryParseAmount(reward, pool?.currency1), earlyReward);

  const [approvePool, approvePoolCallback] = useApproveCallback(tryParseAmount(amount, pool?.currency0), params.pool);
  const [approveReward, approveRewardCallback] = useApproveCallback(tryParseAmount(reward, pool?.currency1), params.pool);

  const handleStake = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await stake.callback();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [amount, pool, stake]);

  const handleUnstake = useCallback(async () => {
    setLoading(true);
    setExitPoolOpen(false);
    try {
      const tx = await unstake.callback();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [amount, pool, unstake]);

  const handleAddReward = useCallback(async () => {
    setLoading(true);
    setAddRewardOpen(false);
    try {
      const tx = await addReward.callback();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [reward, pool, addReward]);

  if (!account) {
    return (
      <AppBody>
        <PageWrapper>
          <TitleRow>
            <TYPE.largeHeader>Connect to a wallet to view.</TYPE.largeHeader>
          </TitleRow>
        </PageWrapper>
      </AppBody>
    );
  }

  if (!ethers.utils.isAddress(params.pool)) {
    return (
      <AppBody>
        <PageWrapper>
          <TitleRow>
            <TYPE.largeHeader>Pool not found</TYPE.largeHeader>
          </TitleRow>
        </PageWrapper>
      </AppBody>
    );
  }

  if (!pool || !pool?.name || !pool?.balances) {
    return (
      <AppBody>
        <PageWrapper>
          <TitleRow>
            <TYPE.largeHeader>Loading Pool.</TYPE.largeHeader>
          </TitleRow>
        </PageWrapper>
      </AppBody>
    );
  }

  const unstakingDisabled = amount === '' || amount === '0' || loading || pool?.userStaked?.lte(0);
  const stakingDisabled = amount === '' || amount === '0' || loading;

  return (
    <AppBody>

      <Modal isOpen={exitPoolOpen} onDismiss={() => setExitPoolOpen(false)} minHeight={30} maxHeight={100}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '10px' }}>
          <TYPE.mediumHeader style={{ textAlign: 'center', marginBottom: '10px' }}>
            Warning
          </TYPE.mediumHeader>
          <TYPE.body style={{ textAlign: 'center', marginBottom: '10px' }}>
            Exiting the pool means forfeiting your current staking position if the pool has not reached maturity.
          </TYPE.body>
          <TYPE.body style={{ textAlign: 'center', marginBottom: '10px' }}>
            Upon exiting, you will receive your staked tokens and any rewards earned up to the point of exit (your current rewards are {formatEther(pool?.reward?.sub(pool?.reward?.mod(1e12)) ?? '0')} {pool?.currency1?.symbol}).
          </TYPE.body>
          <TYPE.body style={{ textAlign: 'center', marginBottom: '10px' }}>
            Are you sure you want to exit?
          </TYPE.body>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            <ButtonError error disabled={unstakingDisabled} style={{ marginRight: '10px', height: '60px' }} onClick={handleUnstake}>Exit Pool</ButtonError>
            <ButtonSecondary style={{ height: '60px' }} onClick={() => setExitPoolOpen(false)}>Cancel</ButtonSecondary>
          </div>
        </div>
      </Modal>

      <Modal isOpen={addRewardOpen} onDismiss={() => setAddRewardOpen(false)} minHeight={30} maxHeight={100}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '10px' }}>
          <TYPE.mediumHeader style={{ textAlign: 'center', marginBottom: '10px' }}>
            Add Farm Pool Rewards
          </TYPE.mediumHeader>
          <TYPE.body style={{ textAlign: 'center', marginBottom: '10px' }}>
            You can add rewards to this pool. (this is not a staking/farming action and will not yield any rewards for you)
          </TYPE.body>

          <CurrencyInputPanel
            id="reward"
            label="Reward Amount"
            value={reward}
            onUserInput={(val) => setReward(val)}
            currency={pool.currency1}
            disableCurrencySelect={true}
            showMaxButton={false}
          />

          <TYPE.body style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
            Early unlockable rewards to add (from the rewards you are trying to add): {earlyReward}%
          </TYPE.body>

          <Slider value={earlyReward} onChange={(val) => setEarlyReward(val)} min={50} max={100} />

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            {(approveReward === ApprovalState.NOT_APPROVED || approveReward === ApprovalState.PENDING) && (
              <ButtonPrimary style={{ marginRight: '10px', height: '60px' }} disabled={loading} onClick={approveRewardCallback}>
                {approveReward === ApprovalState.PENDING ? 'Approving..' : 'Approve'}
              </ButtonPrimary>
            )}
            {(approveReward === ApprovalState.APPROVED || approveReward === ApprovalState.UNKNOWN) && (
              <ButtonPrimary style={{ marginRight: '10px', height: '60px' }} disabled={loading || reward === '' || reward === '0'} onClick={handleAddReward}>Add Reward</ButtonPrimary>
            )}
            <ButtonSecondary style={{ height: '60px' }} onClick={() => setAddRewardOpen(false)}>Cancel</ButtonSecondary>
          </div>
        </div>
      </Modal>

      <PageWrapper>
        <PulsingDot status={pool.status} />
        <TitleRow>
          <TYPE.main style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TYPE.link onClick={() => hisotry.goBack()} style={{ cursor: 'pointer' }}>
              <ArrowLeft style={{ paddingRight: '5px' }} size={32} />
            </TYPE.link>
            {pool.name.replace('VSP: ', '')}
          </TYPE.main>
          <TYPE.main>{formatEther(pool?.minimumMaturity.sub(pool?.minimumMaturity.mod(1e12)) ?? '0')} APY ({pool?.currency1?.symbol})</TYPE.main>
        </TitleRow>
      </PageWrapper>

      <LightCard style={{ padding: '1rem', borderRadius: '30px' }}>

        <DetailsBlock>
          <DetailsRow>
            <ArrowDown />
            <TYPE.main style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              Stake Token:
              {pool?.isLp0 && (
                <QuestionHelper text="This token is detected to be an LP token" />
              )}
            </TYPE.main>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {pool?.isLp0 ? (
                <TYPE.link style={{ cursor: 'pointer', paddingRight: '25px' }} onClick={() => window.open(getEtherscanLink(chainId, pool.currency0?.address, 'address'))}>
                  {pool?.pair0?.token0?.symbol}/{pool?.pair0?.token1?.symbol} LP
                </TYPE.link>
              ) : (
                <TYPE.main>{pool.currency0?.symbol}</TYPE.main>
              )}
              {
                pool?.isLp0 ? (
                  <DoubleCurrencyLogo
                    currency0={{ ...pool.pair0.token0 }}
                    currency1={{ ...pool.pair0.token1 }}
                    size={32}
                  />
                ) : (
                  <CurrencyLogo currency={pool.currency0} size={'32px'} style={{ marginLeft: '8px' }} />
                )
              }
            </div>
            <TYPE.main style={{ fontSize: '10px', marginTop: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {formatEther(pool?.stakeState.stakedTotal.sub(pool?.stakeState.stakedTotal.mod(1e12)) ?? '0')} {pool.currency0?.symbol}
              <QuestionHelper text="This is how much total staked tokens the pool has." />
            </TYPE.main>
          </DetailsRow>

          <DetailsRow>
            <ArrowUp />
            <TYPE.main style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              Reward Token:
              <IconButton onClick={() => setAddRewardOpen(true)}>
                <Plus />
              </IconButton>
              {pool?.isLp1 && (
                <QuestionHelper text="This token is detected to be an LP token" />
              )}
            </TYPE.main>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {pool?.isLp1 ? (
                <TYPE.link style={{ cursor: 'pointer' }} onClick={() => window.open(getEtherscanLink(chainId, pool.currency1?.address, 'address'))}>{pool.currency1?.symbol}</TYPE.link>
              ) : (
                <TYPE.main>{pool.currency1?.symbol}</TYPE.main>
              )}
              <CurrencyLogo currency={pool.currency1} size={'32px'} style={{ marginLeft: '8px' }} />
            </div>
            <TYPE.main style={{ fontSize: '10px', marginTop: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {formatEther(pool?.rewardState.rewardsTotal.sub(pool?.rewardState.rewardsTotal.mod(1e12)) ?? '0')} {pool.currency1?.symbol}
              <QuestionHelper text="This is how much total rewards the pool has. (anyone can add rewards to this pool by pressing the green (+) button above)" />
            </TYPE.main>
            <TYPE.main style={{ fontSize: '10px', marginTop: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {formatEther(pool?.rewardState?.earlyWithdrawReward?.sub(pool?.rewardState?.earlyWithdrawReward?.mod(1e12)) ?? '0')} {pool.currency1?.symbol}
              <QuestionHelper text="This is how much rewards is distributed before reaching maturity from the total rewards. (this is partial reward amount from the total reward and not another reward amount)" />
            </TYPE.main>
          </DetailsRow>
        </DetailsBlock>

        <div style={{ width: '80%', borderTop: '1px solid rgba(255, 255, 255, 0.1)', margin: '0 auto', marginTop: '15px', marginBottom: '15px' }}></div>
        <DetailsBlock style={{ padding: '10px' }}>
          <DetailsRow>
            <TYPE.main>Staking Start: </TYPE.main>
            <TYPE.main>{pool.stakingStarts.toLocaleString()}</TYPE.main>
          </DetailsRow>
          <DetailsRow>
            <TYPE.main>Staking End: </TYPE.main>
            <TYPE.main>{pool.stakingEnds.toLocaleString()}</TYPE.main>
          </DetailsRow>
        </DetailsBlock>
        <DetailsBlock>
          <DetailsRow>
            <TYPE.main>Withdrawing Start: </TYPE.main>
            <TYPE.main>{pool.withdrawStarts.toLocaleString()}</TYPE.main>
          </DetailsRow>
          <DetailsRow>
            <TYPE.main>Maturity: </TYPE.main>
            <TYPE.main>{pool.withdrawEnds.toLocaleString()}</TYPE.main>
          </DetailsRow>
        </DetailsBlock>

        <div style={{ marginTop: '20px', marginBottom: '10px', borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}></div>
        <GreyCard style={{ padding: '0.5rem', borderRadius: '30px', marginBottom: '15px', marginTop: '15px', position: 'relative' }}>
          <DetailsBlock>
            <DetailsRow>
              <TYPE.main>
                Staked:
              </TYPE.main>
              <TYPE.main>{formatEther(pool?.userStaked ?? '0')} {pool.currency0?.symbol}</TYPE.main>
            </DetailsRow>
            <DetailsRow>
              <TYPE.main>
                Reward:
                <QuestionHelper text={`You claim this reward when you exit the pool. (distributed automatically per block/second)`} />
              </TYPE.main>
              <TYPE.main style={{ fontSize: '14px' }}>{formatEther(pool?.reward?.sub(pool?.reward?.mod(1e12)) ?? '0')} {pool.currency1?.symbol}</TYPE.main>
            </DetailsRow>
          </DetailsBlock>


          {pool && pool.stakingCap && pool.stakingCap.gt("0") && (
            <DetailsBlock>
              <DetailsRow>
                <TYPE.main>Available Seat: </TYPE.main>
                {formatEther(pool?.stakingCap.sub(pool?.stakeState?.stakedTotal))}/{formatEther(pool.stakingCap.toString())} {pool.currency0?.symbol}
                <ProgressBar progress={Number(pool.stakeState.stakedTotal) / Number(pool.stakingCap) * 100} />
              </DetailsRow>
            </DetailsBlock>
          )}
        </GreyCard>

        <CurrencyInputPanel
          id="stake"
          value={amount}
          onUserInput={(val) => setAmount(val)}
          currency={pool.currency0}
          disableCurrencySelect={true}
          showMaxButton={false}
        />
        {(approvePool === ApprovalState.NOT_APPROVED || approvePool === ApprovalState.PENDING) && (
          <ButtonPrimary style={{ marginTop: '8px' }} disabled={pool?.status !== 'Staking' || loading} onClick={approvePoolCallback}>
            {approvePool === ApprovalState.PENDING ? 'Approving..' : 'Approve'}
          </ButtonPrimary>
        )}
        {(approvePool === ApprovalState.APPROVED || approvePool === ApprovalState.UNKNOWN) && (
          <ButtonPrimary style={{ marginTop: '8px' }} disabled={stakingDisabled || pool?.status !== 'Staking'} onClick={handleStake}>
            {stake.error ? stake.error.message : loading ? 'Loading..' : 'Stake'}
          </ButtonPrimary>
        )}
        <ButtonError error style={{ marginTop: '8px' }} disabled={unstakingDisabled && pool?.status !== 'Yielding'} onClick={() => setExitPoolOpen(true)}>Exit Pool</ButtonError>
      </LightCard>
    </AppBody >
  );
}
